@tailwind base;
@tailwind components;
@tailwind utilities;

.mapboxgl-popup-content,
.mapboxgl-popup {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.mapboxgl-popup-tip {
  border-top-color: #000099 !important;
}

.custom-text-color text
{
  @apply text-gray-50 bg-gray-50 !important;
  @apply fill-current !important;
}